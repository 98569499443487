/* eslint-disable no-unused-vars */
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { Card, CardContent, CardHeader, CardActions } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"
import { useCookies } from "react-cookie"
import { navigate } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"
import _ from "lodash"
// Data
import { useFirestoreDoc, firestore } from "gatsby-theme-firebase"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
    position: "absolute",
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
  itemTitle: {
    fontFamily: "Lato",
    color: "#363070",
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontFamily: "Lato",
    color: "#8F8DB2",
    fontStyle: "italic",
  },
  text: {
    fontFamily: "Lato",
  },
  textBold: {
    fontFamily: "Lato",
    fontWeight: "bold"
  },
  grid: {
    padding: theme.spacing(0.5, 0.5, 2, 0.5),
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  card: {
    borderRadius: 15,
    height: "100%",
  },
  nipBronze: {
    background: 'rgba(231,231,231,1)'
  },
  nipSilver: {
    background:
      "linear-gradient(30deg, rgba(231,231,231,1) 0%, rgba(140,140,140,1) 50%, rgba(255" +
      ",254,254,1) 100%)",
  },
  nipGold: {
    background:
      "linear-gradient(30deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
  },
  cardHeaderText: {
    fontFamily: "Lato",
    lineHeight: 1.2,
    color: "#FFFF",
  },
  super: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Lato",
    color: "#363070",
  },
  btnBronze: {
    fontFamily: "lato",
    fontWeight: "bolder"
  },
  btnSilver: {
    fontFamily: "lato",
    fontWeight: "bolder",
    background:
      "linear-gradient(30deg, rgba(231,231,231,1) 0%, rgba(140,140,140,1) 50%, rgba(255" +
      ",254,254,1) 100%)",
  },
  btnGold: {
    fontFamily: "lato",
    fontWeight: "bolder",
    background:
      "linear-gradient(30deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
  },
}))

export default function OfferCard() {
  const classes = useStyles()
  const [cookies, setCookie] = useCookies(["selectedOffer"])
  const { locale, defaultLang } = useLocalization()
  const [data, loading] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("offer")
  )
  // eslint-disable-next-line no-console
  // console.log(data, loading, locale)
  const selectOffer = offer => {
    setCookie("selectedOffer", offer)
    navigate(`${locale === defaultLang ? "" : `/${locale}`}/app/cart`, {
      state: { prevPath: window.location.pathname },
    })
  }
  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-end"
        className={classes.grid}
      >
        {data &&
          !loading &&
          data.cards.map(offer => (
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              lg={3}
              xl={2}
              className={classes.gridItem}
              key={offer.priceId}
            >
              <Card className={classes.card}>
                <CardHeader
                  title={offer.title || ""}
                  subheader={offer.subheader || "OFFRE"}
                  className={classes[`nip${_.startCase(offer.color)}`]}
                />
                <CardContent className={classes.cardContent}>
                  <Typography
                    align="center"
                    color="primary"
                    className={classes.itemTitle}
                    variant="h4"
                  >
                    {offer.price || ""}
                    <sup className={classes.super}>€ TTC</sup>
                  </Typography>
                  <Typography
                    className={classes.subtitle}
                    align="center"
                    variant="subtitle2"
                  >
                    {offer.subtitle || ""}
                  </Typography>
                  {offer.content &&
                    offer.content.map((line,i) => (
                      <Typography
                        className={line.search(/<\/?b>/gi) !== -1?classes.textBold:classes.text}
                        paragraph
                        align="center"
                        variant='body1'
                        // eslint-disable-next-line react/no-array-index-key
                        key={i+line+i}
                      >
                        {line.replace(/<\/?b>/gi,'')}
                      </Typography>
                    ))}
                </CardContent>
                <CardActions>
                  <Button
                    className={classes[`btn${_.startCase(offer.color)}`]}
                    size="large"
                    onClick={() => selectOffer(offer.priceId)}
                    variant="contained"
                    fullWidth
                  >
                    {offer.btn || "COMMANDER"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  )
}
