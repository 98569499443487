import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Link, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import SvgIcon from "@material-ui/core/SvgIcon"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
  },
  logo: {
    margin: theme.spacing(2),
  },
  goldBar: {
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    position: "relative",
    top: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  goldBar2: {
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    marginBottom: theme.spacing(2),
  },
  icon: {
    margin: theme.spacing(0, 2),
  },
  gridItem: {
    padding: theme.spacing(3),
  },
  payment: {
    width: 100,
  },
}))

export default function Footer({ offerRef, data, navRef }) {
  const classes = useStyles()
  const executeScroll = ref => ref.current.scrollIntoView()

  return (
    <div className={classes.root}>
      <div className={classes.goldBar} />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          sm={6}
          md={3}
          className={classes.gridItem}
          container
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2FlogoAndName%400.5X.png?alt=media&token=c92bc7a7-d7ee-41f2-b74c-84ce24ea0616"
              alt="QR Stories logo"
            />
          </Grid>
          <Grid item xs={12}>
            <a href="https://www.facebook.com/QR-Stories-107449984517886/">
              <SvgIcon className={classes.icon} color="secondary">
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
              </SvgIcon>
            </a>
            <a href="https://instagram.com/qrstories26?igshid=4ug3is9ea7qv">
              <SvgIcon className={classes.icon} color="secondary">
                <path d="M11.984 16.815c2.596 0 4.706-2.111 4.706-4.707 0-1.409-.623-2.674-1.606-3.538-.346-.303-.735-.556-1.158-.748-.593-.27-1.249-.421-1.941-.421s-1.349.151-1.941.421c-.424.194-.814.447-1.158.749-.985.864-1.608 2.129-1.608 3.538 0 2.595 2.112 4.706 4.706 4.706zm.016-8.184c1.921 0 3.479 1.557 3.479 3.478 0 1.921-1.558 3.479-3.479 3.479s-3.479-1.557-3.479-3.479c0-1.921 1.558-3.478 3.479-3.478zm5.223.369h6.777v10.278c0 2.608-2.114 4.722-4.722 4.722h-14.493c-2.608 0-4.785-2.114-4.785-4.722v-10.278h6.747c-.544.913-.872 1.969-.872 3.109 0 3.374 2.735 6.109 6.109 6.109s6.109-2.735 6.109-6.109c.001-1.14-.327-2.196-.87-3.109zm2.055-9h-12.278v5h-1v-5h-1v5h-1v-4.923c-.346.057-.682.143-1 .27v4.653h-1v-4.102c-1.202.857-2 2.246-2 3.824v3.278h7.473c1.167-1.282 2.798-2 4.511-2 1.722 0 3.351.725 4.511 2h7.505v-3.278c0-2.608-2.114-4.722-4.722-4.722zm2.722 5.265c0 .406-.333.735-.745.735h-2.511c-.411 0-.744-.329-.744-.735v-2.53c0-.406.333-.735.744-.735h2.511c.412 0 .745.329.745.735v2.53z" />
              </SvgIcon>
            </a>
            {/* <a href="https://facebook.com">
              <SvgIcon className={classes.icon} color="secondary">
                <path
                  d="M16.283 13.883c0 2.026-1.615 3.354-4.026 3.354-2.121 0-4.252-.965-4.242-2.627.003-.501.376-.961.872-.961 1.249 0 1.237 1.863 3.225 1.863 1.396 0 1.872-.764 1.872-1.296 0-1.924-6.03-.745-6.03-4.362 0-1.958 1.602-3.309 4.12-3.161 2.401.142 3.809 1.202 3.944 2.193.067.647-.361 1.151-1.105 1.151-1.086 0-1.196-1.454-3.067-1.454-.844 0-1.556.352-1.556 1.116 0 1.597 5.993.67 5.993 4.184zm7.717-8.883v14c0 2.761-2.238 5-5 5h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5zm-4.675 8.311c.904-5.177-3.61-9.608-8.702-8.653-3.881-2.374-8.335 2.151-5.956 6.02-.925 5.165 3.589 9.654 8.722 8.673 3.888 2.357 8.329-2.174 5.936-6.04z"/>
              </SvgIcon>
            </a>
            <a href="https://facebook.com">
              <SvgIcon className={classes.icon} color="secondary">
                <path
                  d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.238 5-5 5zm1-12.508c0-3.58-3.589-6.492-8-6.492s-8 2.912-8 6.492c0 3.209 2.846 5.897 6.691 6.405.26.056.615.172.704.395.081.202.053.519.026.723l-.114.685c-.035.202-.161.791.693.431.854-.36 4.607-2.713 6.286-4.645h-.001c1.16-1.271 1.715-2.561 1.715-3.994zm-10.978 2.067h-2.242l-.108-.043v-.001l-.001-.001-.003-.003-.044-.107v-3.486c0-.086.07-.156.156-.156h.562c.085 0 .155.07.155.156v2.768h1.525c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156zm8.314 0h-2.242l-.108-.043-.004-.005-.044-.107v-3.486l.044-.107.004-.005.108-.044h2.242c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156h-1.525v.589h1.525c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156h-1.525v.589h1.525c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156zm-6.961 0h-.562c-.086 0-.156-.069-.156-.155v-3.486c0-.086.07-.156.156-.156h.562c.086 0 .155.07.155.156v3.486c0 .085-.069.155-.155.155zm1.418 0h-.561c-.086 0-.156-.069-.156-.155v-3.486c0-.086.07-.156.156-.156h.561l.013.001.015.002.009.002.015.004.005.002.014.006.013.008.008.005.012.01.003.003.01.011.013.015 1.597 2.158v-2.071c0-.086.07-.156.156-.156h.561c.086 0 .156.07.156.156v3.486c0 .085-.07.155-.156.155h-.561l-.04-.005-.002-.001-.016-.005-.007-.003-.012-.006-.01-.006-.001-.001-.04-.039-1.599-2.16v2.071c0 .085-.07.155-.156.155z"/>
              </SvgIcon>
            </a> */}
          </Grid>
        </Grid>
        <Grid item sm={6} md={3} className={classes.gridItem}>
          <Typography color="secondary" className={classes.text}>
            {data.footerAddress}
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          md={3}
          className={classes.gridItem}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Button
              color="secondary"
              className={classes.link}
              variant="text"
              onClick={() => executeScroll(navRef)}
            >
              {data.footerLink1}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              className={classes.link}
              onClick={() => executeScroll(offerRef)}
              variant="text"
            >
              {data.footerLink2}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Link
              color="secondary"
              className={classes.link}
              target="_blank"
              rel="noopener"
              href={data.footerLink3Href}
            >
              {data.footerLink3}
            </Link>
          </Grid>
          <Grid item xs={6} className={classes.center}>
            <Link
              color="secondary"
              className={classes.link}
              target="_blank"
              href={data.footerLink4Href}
              rel="noopener"
            >
              {data.footerLink4}
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          sm={6}
          md={3}
          className={classes.gridItem}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={2}>
            <SvgIcon fontSize="large" color="secondary">
              <path d="M15.827 12.506c0 .672-.31 1.175-.771 1.175-.293 0-.468-.106-.589-.237l-.007-1.855c.13-.143.31-.247.596-.247.456-.001.771.51.771 1.164zm3.36-1.253c-.312 0-.659.236-.659.798h1.291c0-.562-.325-.798-.632-.798zm4.813-5.253v12c0 1.104-.896 2-2 2h-20c-1.104 0-2-.896-2-2v-12c0-1.104.896-2 2-2h20c1.104 0 2 .896 2 2zm-17.829 7.372c0-1.489-1.909-1.222-1.909-1.784 0-.195.162-.271.424-.271.38 0 .862.116 1.242.321v-1.176c-.414-.165-.827-.228-1.241-.228-1.012.001-1.687.53-1.687 1.414 0 1.382 1.898 1.158 1.898 1.754 0 .231-.201.305-.479.305-.414 0-.947-.171-1.366-.399v1.192c.464.2.935.283 1.365.283 1.038.001 1.753-.512 1.753-1.411zm2.422-3.054h-.949l-.001-1.084-1.219.259-.005 4.006c0 .739.556 1.285 1.297 1.285.408 0 .71-.074.876-.165v-1.016c-.16.064-.948.293-.948-.443v-1.776h.948v-1.066zm2.596 0c-.166-.06-.75-.169-1.042.369l-.078-.369h-1.079v4.377h1.248v-2.967c.295-.388.793-.313.952-.262v-1.148zm1.554 0h-1.253v4.377h1.253v-4.377zm0-1.664l-1.253.266v1.017l1.253-.266v-1.017zm4.314 3.824c0-1.454-.826-2.244-1.703-2.243-.489 0-.805.23-.978.392l-.065-.309h-1.099v5.828l1.249-.265.003-1.413c.179.131.446.316.883.316.893 0 1.71-.719 1.71-2.306zm3.943.045c0-1.279-.619-2.288-1.805-2.288-1.188 0-1.911 1.01-1.911 2.281 0 1.506.852 2.267 2.068 2.267.597 0 1.045-.136 1.384-.324v-1.006c-.34.172-.731.276-1.227.276-.487 0-.915-.172-.971-.758h2.444l.018-.448z" />
            </SvgIcon>
          </Grid>
          <Grid item xs>
            <Typography color="secondary">{data.footerPaymentText}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.goldBar2} />
      <Typography align="center" color="secondary">
        <i>&copy;</i> QR STORIES {new Date().getFullYear()}
      </Typography>
    </div>
  )
}
