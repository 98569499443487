import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import MenuIcon from "@material-ui/icons/Menu"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import { Button, Typography } from "@material-ui/core"
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n"
import { navigate as navigateGats } from "gatsby"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    margin: theme.spacing(2),
    width: theme.spacing(25),
  },
  navLinks: {
    marginTop: theme.spacing(0.3),
    position: "absolute",
    right: theme.spacing(22),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  menuButton: {
    position: "absolute",
    right: theme.spacing(3),
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  navLink: {
    marginRight: theme.spacing(5),
    fontFamily: "lato",
  },
  button: {
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
    padding: theme.spacing(0.8, 1.5),
    fontFamily: "lato",
    position: "absolute",
    right: theme.spacing(4),
    borderRadius: 20,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    background:
      "linear-gradient(30deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: theme.spacing(0.5),
    width: "100%",

    position: "relative",
    bottom: theme.spacing(1),
    background:
      "linear-gradient(30deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
  },
  drawer: {
    width: theme.spacing(30),
    backgroundColor: theme.palette.primary.dark,
    height: "100%",
  },
  navLinkDrawer: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))

export default function NavBar({
  missionRef,
  howRef,
  offerRef,
  // contactRef,
  data,
  navRef,
}) {
  const classes = useStyles()
  const { locale, defaultLang } = useLocalization()
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setOpen(!open)
  }

  const executeScroll = ref => ref.current.scrollIntoView()

  const navigate = async ref => {
    await toggleDrawer()
    executeScroll(ref)
  }

  return (
    <>
      <div className={classes.root} ref={navRef}>
        <AppBar position="static">
          <Toolbar>
            <img
              className={classes.logo}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2FlogoAndName.svg?alt=media&token=4a841aa8-5d0e-48c5-a372-bfdfd680684c"
              alt="QR Stories -Une empreinte pour la vie"
            />
            <div className={classes.navLinks}>
              <Button
                color="inherit"
                onClick={() => executeScroll(missionRef)}
                variant="text"
                className={classes.navLink}
              >
                {data.navBarMissionButton}
              </Button>
              <Button
                color="inherit"
                onClick={() => executeScroll(howRef)}
                variant="text"
                className={classes.navLink}
              >
                {data.navBarHowButton}
              </Button>
              <Button
                color="inherit"
                onClick={() => executeScroll(offerRef)}
                variant="text"
                className={classes.navLink}
              >
                {data.navBarOfferButton}
              </Button>
              {/* <Button
                color="inherit"
                onClick={() => executeScroll(contactRef)}
                variant="body2"
                className={classes.navLink}
              >
                {data.navBarContactButton}
              </Button> */}
              {locale === defaultLang ? (
                <Button color="secondary" onClick={() => navigateGats("/en")}>
                EN
                </Button>
              ) : (
                <Button color="secondary" onClick={() => navigateGats("/")}>
                FR
                </Button>
              )}
            </div>

            <LocalizedLink
              className={classes.button}
              to="/app"
              variant="contained"
            >
              <Typography>{data.navBarConnectButton}</Typography>
            </LocalizedLink>
            <IconButton
              className={classes.menuButton}
              onClick={() => toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <div className={classes.goldBar} />
        </AppBar>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer()}
        onOpen={() => toggleDrawer()}
      >
        <div className={classes.drawer}>
          <List>
            <ListItem
              className={classes.navLinkDrawer}
              button
              component="a"
              onClick={() => navigate(missionRef)}
            >
              <ListItemText primary={data.navBarMissionButton} />
            </ListItem>
            <ListItem
              className={classes.navLinkDrawer}
              button
              component="a"
              onClick={() => navigate(howRef)}
            >
              <ListItemText primary={data.navBarHowButton} />
            </ListItem>
            <ListItem
              className={classes.navLinkDrawer}
              button
              component="a"
              onClick={() => navigate(offerRef)}
            >
              <ListItemText primary={data.navBarOfferButton} />
            </ListItem>
            {/* <ListItem
              className={classes.navLinkDrawer}
              button
              component="a"
              onClick={() => navigate(contactRef)}
            >
              <ListItemText primary={data.navBarContactButton} />
            </ListItem> */}
            <ListItem
              className={classes.navLinkDrawer}
              button
              component="a"
              href={`${locale === defaultLang ? "" : `/${  locale}`}/app`}
            >
              <ListItemText primary={data.navBarConnectButton} />
            </ListItem>
            <ListItem className={classes.navLinkDrawer}>
              {locale === defaultLang ? (
                <Button color="secondary" onClick={() => navigateGats("/en")}>
                EN
                </Button>
              ) : (
                <Button color="secondary" onClick={() => navigateGats("/")}>
                FR
                </Button>
              )}
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  )
}
