import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { Paper } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
    position: "absolute",
  },
  title: {
    margin: theme.spacing(4, "auto"),
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
  itemTitle: {
    fontFamily: "Lato",
    color: theme.palette.primary.main,
    fontWeight: "600",
  },
  text: {
    fontFamily: "Lato",
    fontStyle: "italic",
    color: theme.palette.primary.main,
  },
  gridItem: {
    padding: theme.spacing(4, 4),
  },
  surface: {
    padding: theme.spacing(2),
    backgroundColor: "#E4E3F1",
    width: "100%",
    height: "100%",
  },
  image1: {
    margin: theme.spacing(8, "auto", 2, "auto"),
    display: "block",
  },
  image2: {
    margin: theme.spacing(3.7, "auto"),
    display: "block",
  },
  image3: {
    margin: theme.spacing(5, "auto", 2, "auto"),
    display: "block",
  },
}))

export default function More({ data }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.goldBar} />
      <Grid container direction="row" justify="center" alignItems="stretch">
        <Grid className={classes.gridItem} xs={12} item>
          <Typography
            align="center"
            className={classes.title}
            variant="h5"
            component="h2"
          >
            {data.moreTitle}
          </Typography>
        </Grid>
        <Grid className={classes.gridItem} item md={4}>
          <Paper className={classes.surface} square>
            <img
              className={classes.image1}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fformulaire%20icon.png?alt=media&token=3218684d-0847-475b-ba4f-f247c6c77e77"
              alt="formulaire icon"
            />
            <Typography
              paragraph
              className={classes.itemTitle}
              align="center"
              variant="h6"
            >
              {data.moreItem1Title}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem1P1}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem1P2}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem1P3}
            </Typography>
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} item md={4}>
          <Paper className={classes.surface} square>
            <img
              className={classes.image2}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fsur%20le%20qr%20code%20icon.png?alt=media&token=3ca95536-246c-4dc0-9a2c-7a6bad76ef12"
              alt="mobile qr code icon"
            />
            <Typography
              paragraph
              className={classes.itemTitle}
              align="center"
              variant="h6"
            >
              {data.moreItem2Title}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem2P1}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem2P2}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem2P3}
            </Typography>
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} item md={4}>
          <Paper className={classes.surface} square>
            <img
              className={classes.image3}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fgaranties%20icon.png?alt=media&token=6787075d-7c68-4a85-86e5-629619fd7281"
              alt="mobile qr code icon"
            />
            <Typography
              paragraph
              className={classes.itemTitle}
              align="center"
              variant="h6"
            >
              {data.moreItem3Title}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem3P1}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1">
              {data.moreItem3P2}
            </Typography>
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} xs={12} item>
          <Typography
            align="center"
            className={classes.title}
            variant="h6"
            component="h3"
          >
            {data.moreTitle2}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
