import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
    position: "absolute",
  },
  imageContainer2: {
    width: "100%",
    backgroundColor: "#262536",
  },
  image1: {
    width: "100%",
    height: "100%",
    display: "block",
    margin: theme.spacing(0),
  },
  image2: {
    width: "100%",
    height: "100%",
    display: "block",
    margin: theme.spacing(0),
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
  itemTitle: {
    marginTop: theme.spacing(4),
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
  },
  subtitle: {
    fontFamily: "Lato",
    color: theme.palette.secondary.main,
    fontStyle: "italic",
  },
  text: {
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
  },
  gridItem: {
    padding: theme.spacing(6, 4),
  },
}))

export default function Mission({ missionRef, data }) {
  const classes = useStyles()
  return (
    <div className={classes.root} ref={missionRef}>
      <div className={classes.goldBar}></div>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="stretch"
      >
        <Grid
          item
          xs={12}
          container
          justify="center"
          className={classes.gridItem}
        >
          <Grid item>
            <Typography className={classes.title} variant="h5" component="h3">
              {data.missionTitle1}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          className={classes.gridItem}
        >
          <Grid item>
            <img
              className={classes.image1}
              alt="Graveyard"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2FQRSTORIES-MISSION%201.png?alt=media&token=d536ef45-b635-41a0-a582-43ebc26cc1cb"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.itemTitle} variant="h6">
              {data.missionGridItem1Title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="left"
              variant="subtitle1"
              className={classes.subtitle}
            >
              {data.missionGridItem1Subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" variant="body1" className={classes.text}>
              {data.missionGridItem1P1}
            </Typography>
            <Typography
              paragraph
              align="left"
              variant="body1"
              className={classes.text}
            >
              {data.missionGridItem1P2}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          className={classes.gridItem}
        >
          <Grid item className={classes.imageContainer2} container>
            <img
              className={classes.image2}
              alt="Tombstone"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2FQRSTORIES-MISSION%202-%20NEW.png?alt=media&token=41b3fb8f-73af-4521-a625-a17fef437d91"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.itemTitle} variant="h6">
              {data.missionGridItem2Title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="left"
              variant="subtitle1"
              className={classes.subtitle}
            >
              {data.missionGridItem2Subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              paragraph
              align="left"
              variant="body1"
              className={classes.text}
            >
              {data.missionGridItem2P1}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
