import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FFFF",
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
    position: "absolute",
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  itemTitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  text: {
    fontFamily: "Lato",
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  gridItemTitle: {
    padding: theme.spacing(6, 4, 0, 6),
  },
  img1: {
    display: "block",
    margin: theme.spacing(0, "auto", 2, "auto"),
  },
  img2: {
    display: "block",
    margin: theme.spacing(4.2, "auto", 2, "auto"),
  },
  img3: {
    display: "block",
    margin: theme.spacing(0, "auto", 2, "auto"),
  },
  img4: {
    display: "block",
    margin: theme.spacing(3.5, "auto", 2, "auto"),
  },
  img5: {
    display: "block",
    margin: theme.spacing(0, "auto", 2, "auto"),
  },
  img6: {
    display: "block",
    margin: theme.spacing(3.5, "auto", 2, "auto"),
  },
  img7: {
    display: "block",
    margin: theme.spacing(0, "auto", 2, "auto"),
  },
  img8: {
    display: "block",
    margin: theme.spacing(3.5, "auto", 2, "auto"),
  },
  textSurface: {
    backgroundColor: "#E4E3F1",
    padding: theme.spacing(4, 2),
    width: "100%",
    [theme.breakpoints.up("xl")]: {
      height: theme.spacing(35),
    },
    [theme.breakpoints.down("lg")]: {
      height: theme.spacing(50),
    },
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(46),
    },
  },
  bottomText: {
    fontFamily: "Lato",
  },
  button: {
    display: "block",
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
    padding: theme.spacing(1, 2),
    fontFamily: "lato",
    borderRadius: 20,
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
  },
}))

export default function How({ howRef, data, offerRef }) {
  const classes = useStyles()
  const executeScroll = ref => ref.current.scrollIntoView()
  return (
    <div className={classes.root} ref={howRef}>
      <div className={classes.goldBar}></div>
      <Grid container direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} className={classes.gridItemTitle}>
          <Typography
            className={classes.title}
            paragraph
            align="center"
            variant="h5"
            color="primary"
          >
            {data.howTitle1}
          </Typography>
        </Grid>
        <Grid item sm={6} lg={3} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img1}
              alt="step 1"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2F1.png?alt=media&token=656d1ab3-6729-4af6-860d-56d8c46144f3"
            />
            <img
              className={classes.img2}
              alt="account creation"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Ficon%20creation.png?alt=media&token=d97b2c17-4922-4e74-b633-d2a2fac12bbc"
            />
            <div className={classes.textSurface}>
              <Typography
                paragraph
                className={classes.itemTitle}
                align="center"
                variant="body1"
              >
                {data.howItem1Title}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem1P1}
                <Typography
                  className={classes.text}
                  align="left"
                  variant="body1"
                ></Typography>
                {data.howItem1P2}
                <Typography
                  className={classes.text}
                  align="left"
                  variant="body1"
                ></Typography>
                {data.howItem1P3}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} lg={3} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img3}
              alt="step 2"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2F2.png?alt=media&token=06cde8d9-3ed9-4af3-ae9e-dbd0d0190507"
            />
            <img
              className={classes.img4}
              alt="Ordering the plaque"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Ficon%20qr.png?alt=media&token=d1fdab2f-7262-493c-b9c9-43fa29c08bbb"
            />
            <div className={classes.textSurface}>
              <Typography
                paragraph
                className={classes.itemTitle}
                align="center"
                variant="body1"
              >
                {data.howItem2Title}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem2P1}
                <Typography
                  className={classes.text}
                  align="left"
                  variant="body1"
                ></Typography>
                {data.howItem2P2}
                <Typography
                  className={classes.text}
                  align="left"
                  variant="body1"
                ></Typography>
                {data.howItem2P3}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem2P4}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} lg={3} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img5}
              alt="step 3"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2F3.png?alt=media&token=0cd63475-e557-406a-91fc-cb6228f72d86"
            />
            <img
              className={classes.img6}
              alt="Writing the biography to tell the story of the person"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Ficon%20memoire.png?alt=media&token=6870aaeb-cb90-42c8-994d-13bc0a3c1915"
            />
            <div className={classes.textSurface}>
              <Typography
                paragraph
                className={classes.itemTitle}
                align="center"
                variant="body1"
              >
                {data.howItem3Title}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem3P1}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem3P2}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem3P3}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} lg={3} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img7}
              alt="step 4"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2F4.png?alt=media&token=3947fafb-33aa-473f-af40-fa84d619d45c"
            />
            <img
              className={classes.img8}
              alt="Plaque manufacturing and delivery"
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Ficon%20plaque.png?alt=media&token=97714430-48b4-4dee-b189-1ff1c63cdb8d"
            />
            <div className={classes.textSurface}>
              <Typography
                paragraph
                className={classes.itemTitle}
                align="center"
                variant="body1"
              >
                {data.howItem4Title}
              </Typography>

              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem4P1}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem4P2}
              </Typography>
              <Typography
                className={classes.text}
                align="left"
                color="primary"
                variant="body1"
              >
                {data.howItem4P3}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.bottomText}
            align="center"
            color="primary"
            variant="h6"
          >
            {data.howTitle2P1}
          </Typography>
          <Typography
            paragraph
            className={classes.bottomText}
            align="center"
            color="primary"
            variant="h6"
          >
            {data.howTitle2P2}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Button
                className={classes.button}
                onClick={() => executeScroll(offerRef)}
              >
                {data.howConnectBtn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
