import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  imageContainer: {
    flexGrow: 1,
    // Need to upgrade this to local using gatsby img
    overflow: "hidden",
    background:
      "url(https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2FQRSTORIES-LIERRES%201.png?alt=media&token=e8d222a2-f337-4f72-8eab-e258019b9cd5)",
    
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    // -----
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0),
    },
    padding: theme.spacing(5, 10),
    width: "100%",
    height: "100%",
  },
  button: {
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(5),
    fontFamily: "lato",
    borderRadius: 20,
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    display: "block",
  },
  surface: {
    background: "rgba(255,255,255,0.6)",
    width: "100%",
    height: "100%",
    padding: theme.spacing(5),
  },
  text: {
    fontFamily: "EB Garamond",
    fontWeight: 800,
    textAlign: "center",
  },
}))

export default function Slider({ data, offerRef }) {
  const classes = useStyles()
  const executeScroll = ref => ref.current.scrollIntoView()
  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={0} className={classes.surface}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <img
                    alt="QR Stories"
                    src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Flogo%401x.png?alt=media&token=8f7d726f-9b8d-46de-a615-f9f36c7c0b30"
                  />
                </Grid>
                <Grid>
                  <Typography
                    className={classes.text}
                    variant="h6"
                    component="h2"
                  >
                    {data.sliderL1}
                  </Typography>
                  <Typography
                    className={classes.text}
                    variant="h6"
                    component="h2"
                  >
                    {data.sliderL2}
                  </Typography>
                  <Typography
                    className={classes.text}
                    variant="h6"
                    component="h2"
                  >
                    {data.sliderL3}
                  </Typography>
                  <Typography
                    className={classes.text}
                    variant="h6"
                    component="h2"
                  >
                    {data.sliderL4}
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    size="large"
                    className={classes.button}
                    onClick={() => executeScroll(offerRef)}
                    variant="contained"
                    color="secondary"
                  >
                    {data.sliderConnectButton}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
