import React from "react"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { useCookies } from "react-cookie"

export default function SimpleSnackbar({ data }) {
  const [cookies, setCookie] = useCookies(["hasAcceptedCookies"])
  const [open, setOpen] = React.useState(
    cookies.hasAcceptedCookies ? false : true
  )
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
    setCookie("hasAcceptedCookies", true, {
      path: "/",
      // secure: true
    })
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        message={data.cookieSnackText}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleClose}
            >
              {data.cookieSnackBtn}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  )
}
