import React, { useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import NavBar from "../components/landing/navBar"
import Slider from "../components/landing/slider"
import Mission from "../components/landing/mission"
import How from "../components/landing/how"
import Offer from "../components/landing/offer"
import Features from "../components/landing/features"
import More from "../components/landing/more"
// import Contact from "../components/landing/contact"
import Footer from "../components/landing/footer"
// Data and misc.
import CookieSnack from "../components/landing/cookieSnack"
import Backdrop from "../components/backdrop"


const useStyles = makeStyles(() => ({
  root: {},
}))

export default function Home() {
  const { locale } = useLocalization()
  const [data, isLoading] = useFirestoreDoc(
    firestore.collection("text").doc(locale)
  )
  const classes = useStyles()
  const missionRef = useRef(null)
  const howRef = useRef(null)
  const offerRef = useRef(null)
  // const contactRef = useRef(null)
  const navRef = useRef(null)

  // console.log(data,isLoading,error);
  if (isLoading || !data) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        data={data}
        missionRef={missionRef}
        howRef={howRef}
        offerRef={offerRef}
        // contactRef={contactRef}
        navRef={navRef}
      />
      <Slider data={data} offerRef={offerRef} />
      <Mission data={data} missionRef={missionRef} />
      <How data={data} howRef={howRef} offerRef={offerRef} />
      <Offer data={data} offerRef={offerRef} />
      <Features data={data} />
      <More data={data} />
      {/* <Contact data={data} contactRef={contactRef} /> */}
      <Footer data={data} offerRef={offerRef} navRef={navRef} />
      <CookieSnack data={data} />
    </div>
  )
}
