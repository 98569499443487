import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FFFF",
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    position: "absolute",
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  itemTitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontFamily: "Lato",
    color: "#8F8DB2",
    fontStyle: "italic",
  },
  text: {
    fontFamily: "Lato",
  },
  gridItem: {
    padding: theme.spacing(6, 2),
    width: "100% ",
  },
  img1: {
    display: "block",
    margin: theme.spacing(1, "auto", 2, "auto"),
  },
  img2: {
    display: "block",
    margin: theme.spacing(0, "auto", 2, "auto"),
  },
  img3: {
    display: "block",
    margin: theme.spacing(1, "auto", 2, "auto"),
  },
  img4: {
    display: "block",
    margin: theme.spacing(1.1, "auto", 2, "auto"),
  },
  img5: {
    display: "block",
    margin: theme.spacing(2.2, "auto", 2, "auto"),
  },
  img6: {
    display: "block",
    margin: theme.spacing(1.5, "auto", 2, "auto"),
  },
  surface: {
    width: "100%",
    height: "100%",
  },
}))

export default function Feature({ data }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.goldBar}></div>
      <Grid container direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.title}
            paragraph
            align="center"
            variant="h5"
            color="primary"
          >
            {data.featuresTitle}
          </Typography>
          <Typography
            className={classes.subtitle}
            align="center"
            variant="subtitle1"
          >
            {data.featuresSubtitle}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={2} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img1}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fprofile%20icons%2FBIOGRAPHIE%20ICON.png?alt=media&token=3c7e324c-3bc3-4f80-8a08-a868eb552b9d"
              alt="A memoir of the deceased figure"
            />
            <Typography
              className={classes.itemTitle}
              align="center"
              variant="body1"
            >
              {data.featuresItem1Title}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem1P1}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem1P2}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem1P3}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem1P4}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem1P5}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={6} md={4} lg={2} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img2}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fprofile%20icons%2FLIEU%20DE%20REPOS%20ICON.png?alt=media&token=636b0927-3833-404e-9454-6c2521f412a6"
              alt="A memoir of the deceased figure"
            />
            <Typography
              className={classes.itemTitle}
              align="center"
              variant="body1"
            >
              {data.featuresItem2Title}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem2P1}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem2P2}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem2P3}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem2P4}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem2P5}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={6} md={4} lg={2} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img3}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fprofile%20icons%2FPRIE%CC%80RE%20ICON.png?alt=media&token=47a37b0f-2cae-4187-bf5c-89758b9c75b8"
              alt="A memoir of the deceased figure"
            />
            <Typography
              className={classes.itemTitle}
              align="center"
              variant="body1"
            >
              {data.featuresItem3Title}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem3P1}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem3P2}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem3P3}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem3P4}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem3P5}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem3P6}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={6} md={4} lg={2} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img4}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fprofile%20icons%2FALBUM%20ICON.png?alt=media&token=f8472d02-598d-46fb-a197-5ef65e6a581a"
              alt="A memoir of the deceased figure"
            />
            <Typography
              className={classes.itemTitle}
              align="center"
              variant="body1"
            >
              {data.featuresItem4Title}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem4P1}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem4P2}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={6} md={4} lg={2} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img5}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Flink%20icon.png?alt=media&token=b418fb86-85ce-447c-b524-6018e4cd18e1"
              alt="A memoir of the deceased figure"
            />
            <Typography
              className={classes.itemTitle}
              align="center"
              variant="body1"
            >
              {data.featuresItem5Title}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem5P1}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem5P2}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem5P3}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={6} md={4} lg={2} className={classes.gridItem}>
          <div className={classes.surface}>
            <img
              className={classes.img6}
              src="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Flivre%20d'or%20icon.png?alt=media&token=5c2d4598-88ca-4c2a-9f20-8c1e1884f876"
              alt="A memoir of the deceased figure"
            />
            <Typography
              className={classes.itemTitle}
              align="center"
              variant="body1"
            >
              {data.featuresItem6Title}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem6P1}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem6P2}
            </Typography>
            <Typography
              className={classes.item}
              align="left"
              color="primary"
              variant="body2"
            >
              {data.featuresItem6P3}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
