import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
// Other
import OfferGrid from "../offerGrid"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  goldBar: {
    marginTop: theme.spacing(1),
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253" +
      ",233,211,1) 100%)",
    position: "absolute",
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
  itemTitle: {
    fontFamily: "Lato",
    color: "#363070",
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontFamily: "Lato",
    color: "#8F8DB2",
    fontStyle: "italic",
  },
  text: {
    fontFamily: "Lato",
  },
  gridItem: {
    padding: theme.spacing(4),
  },
}))

export default function Offer({ offerRef, data }) {
  const classes = useStyles()
  return (
    <div className={classes.root} ref={offerRef}>
      <div className={classes.goldBar} />
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="stretch"
      >
        <Grid xs={12} item className={classes.gridItem}>
          <Typography
            align="center"
            className={classes.title}
            variant="h5"
            component="h3"
          >
            {data.offerTitle}
          </Typography>
        </Grid>
      </Grid>
      <OfferGrid />
    </div>
  )
}
